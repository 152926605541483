<template>
    <el-table :data="tableData" style="width: 100%" max-height="250">
        <el-table-column :label="$t('news.name6')" border>
            <template #default="scope">
                <el-input v-model="scope.row.propertyKey" size="small" :placeholder="$t('inputTit.shuru')"/>
            </template>
        </el-table-column>
        <el-table-column :label="$t('news.name7')">
            <template #default="scope">
                <el-input v-model="scope.row.propertyValue" size="small" :placeholder="$t('inputTit.shuru')"/>
            </template>
        </el-table-column>
        <el-table-column fixed="right" width="80" :label="$t('button.operation')">
            <template #default="scope">
                <el-button link type="primary" size="small"
                @click.prevent="deleteRow(scope.$index)" > {{$t('button.del')}} </el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-button class="mt-4" style="width: 100%" @click="onAddItem" > {{$t('button.add')}} </el-button>
</template>
<script setup>
    import { ref,defineExpose } from 'vue'

    const tableData = ref([])

    const deleteRow = (index)=>{
        tableData.value.splice(index, 1)
    }
    const onAddItem = ()=>{
        let tableData_ = tableData.value
        if(tableData_.length === 0){
            tableData_ = [{propertyKey:'',propertyValue:''}]
        }else{
            tableData_.push({propertyKey:'',propertyValue:''})
        }
        tableData.value = tableData_
    }
    const getTableData = ()=>{
        return tableData.value
    }
    defineExpose({getTableData})
</script>
<style lang="scss" scoped>
</style>